import { render, staticRenderFns } from "./PopupGenerique.vue?vue&type=template&id=fa1ffd1a"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_css-loader@5.2.7_lodash@4.17.21_vue-template-compiler@2.7.16_webpack@5.89.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\dev\\Web.PortailCitoyen\\Longueuil.PortailCitoyen.Web\\node_modules\\.pnpm\\vue-hot-reload-api@2.3.4\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fa1ffd1a')) {
      api.createRecord('fa1ffd1a', component.options)
    } else {
      api.reload('fa1ffd1a', component.options)
    }
    module.hot.accept("./PopupGenerique.vue?vue&type=template&id=fa1ffd1a", function () {
      api.rerender('fa1ffd1a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "assets_dev/js/app-components/components/PopupGenerique.vue"
export default component.exports